/* YearPicker Component Styles */
.select-wrapper {
    position: relative;
  }
  
  .year-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    padding: 0.5rem 1rem;
    font-size: 16px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: #FCFAFA;
    cursor: pointer;
    outline: none;
  }
  
  .year-select:focus {
    border-color: #4d90fe;
  }
  

  
  .year-select option {
    padding: 0.5rem 1rem;
  }
  
  