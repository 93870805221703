.ag-theme-alpine {
  --ag-header-foreground-color: #ffff;
  --ag-header-background-color: #2036c7;
  /* --ag-header-cell-hover-background-color: #2036c7 !important; */
  --ag-font-family: monospace;
}
.ag-theme-alpine .ag-row-hover {
  background-color: #e0f1;
}

.ag-theme-alpine-dark {
  --ag-header-foreground-color: #532323;
  --ag-header-background-color: #1c1c1c;
  --ag-header-cell-hover-background-color: #3346D3 !important;
  --ag-background-color:#5b5b5b
  --ag-font-family: monospace;
}

.ag-header-cell-label {
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center;
}

.ag-header-cell-text {
  font-size: 16px !important;
  justify-content: center; /* align horizontal */
  align-items: center;
}
.ag-cell-value {
  font-size: 16px !important;

}
.display-right{
display: flex;
align-content: stretch;
justify-content: flex-end;
}
.my-css-class{
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center;
}
